import styled from "styled-components"

import CtaButton from "../../atoms/cta-button"

import {desktop} from "../../utils/breakpoint"

export const PhotosContainer = styled.section`
  position: relative;
`

export const PhotoGrid = styled.div`
  display: none;
  flex-direction: row;
  height: 731px;
  
  ${desktop} {
    display: flex;
  }
`

export const MobilePhoto = styled.div`
  background-image: url("${({src}) => src}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 240px;
  width: 100%;
  
  ${desktop} {
    display: none;
  }
`

export const LargePhoto = styled.div`
  background-image: url("${({src}) => src}");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 61%;
`

export const SidePhotos = styled.div`
  height: 100%;
  width: 39%;
`

export const MediumPhoto = styled.div`
  background-image: url("${({src}) => src}");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60%;
  width: 100%;
`

export const SmallPhotos = styled.div`
  display: flex;
  flex-direction: row;
  height: 40%;
`

export const SmallPhoto = styled.div`
  background-image: url("${({src}) => src}");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 60%;
`

export const MiniPhoto = styled.div`
  background-image: url("${({src}) => src}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 40%;
`

export const FloatingCtaButton = styled(CtaButton)`
  width: calc(100% - 50px);
  margin: 0 auto 40px;
  text-align: center;
  white-space: nowrap;
  font-size: 16px;
  letter-spacing: 2px;
  justify-content: center;

  left: 25px;
  bottom: 0;
  font-weight: 600;
  line-height: 16px;
  padding-left: 23px;
  padding-right: 23px;
  position: absolute;
  
  ${desktop} {
    font-size: 17px;
    left: 50%;
    letter-spacing: 2px;
    margin: 0 0 69px -170px;
    text-align: unset;
    white-space: unset;
    width: unset;
  }
`