import styled from "styled-components"

import {InstagramPostContainer} from "../../molecules/instagram-post/styles"

import {desktop} from "../../utils/breakpoint"

export const InstagramSwimlaneContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  
  ${InstagramPostContainer}:nth-of-type(3n) {
    flex-basis: 34%;
  }
  
  ${InstagramPostContainer}:nth-of-type(6) {
    ${desktop} {
      display: none
    }
  }
  
  ${desktop} {
    ${InstagramPostContainer}:nth-of-type(3n) {
      flex-basis: unset;
    }
  }
`