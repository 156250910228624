import styled from "styled-components"

import Logo from "../../atoms/logo"
import CtaButton from "../../atoms/cta-button"

import {desktop} from "../../utils/breakpoint"

export const FooterContainer = styled.section`
  background-color: #7b063d;
  background-image: url("/images/footer-bg-low.jpg");
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  padding-top: 100px;
  padding-bottom: 60px;
  position: relative;
  
  ${desktop} {
    background-image: url("/images/footer-bg.png");
    min-height: 300px;
    padding-bottom: 50px;
  }
`

export const StyledLogo = styled(Logo)`
  height: 100px;
  margin: 0 auto 50px;
  
  ${desktop} {
    height: 162px;
    margin: 0 auto 100px;
  }
`

export const Contacts = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  
  ${desktop} {
    align-items: unset;
    flex-direction: row;
    justify-content: space-between;
    margin: 50px auto 0;
    max-width: 820px;
    width: 100%;
  }
`

export const MailingListForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
  padding: 0 24px;
`

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  
  ${desktop} {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }
`

export const Input = styled.input`
  background: #FAFAFB;
  border: 1px solid #E6EAEE;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 16px;
  height: 54px;
  margin-bottom: 8px;
  margin-top: 10px;
  padding: 0 16px;
  width: 100%;
  
  &::placeholder {
    font-family: ProximaNova;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #BDC4CB;
  }
  
  ${desktop} {
    margin-bottom: 0;
    margin-top: 0;
    width: 327px;
  }
`

export const SignUpButton = styled(CtaButton)`
  justify-content: center;
  width: 100%;
  
  ${desktop} {
    height: 54px;
    margin-left: 6px;
    padding: 0 29px;
    width: unset;
  }
`

export const BoldText = styled.h3`
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
`

export const SocialNetworks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;

  ${desktop} {
    margin: 40px auto 0;
    max-width: 390px;
  }
`

export const SocialNetworkLink = styled.a`
  color: #FD98A4;
  fill: #FD98A4;
  margin-right: 0px;
  padding: 10px;
  
  ${desktop} {
    margin-right: 5px;

    &:hover {
      color: #fff;
      fill: #fff;
    }
  }
  
  svg {
    height: 23px;
    width: 23px;
    vertical-align: bottom;
  }
`

export const Credits = styled.div`
  color: ${({theme}) => theme.palette.secondary.accent};
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 15px;
  margin-top: 20px;
  text-align: center;
  text-transform: uppercase;
`

export const CreditBlock = styled.div`
  display: block;
  
  ${desktop} {
    display: inline-block;
    margin-right: 6px;
  }
`

export const ThankYouText = styled.div`
  color: #FECBCC;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-top: 25px;
  text-align: center;
  text-transform: uppercase;
`