import React from 'react';

import Header from "./sections/header"
import Tour from "./sections/tour"
import Videos from "./sections/videos"
import Music from "./sections/music"
import Photos from "./sections/photos"
import Footer from "./sections/footer"

function App() {
  return (
    <>
      <Header />
      <Tour />
      <Videos />
      <Music />
      <Photos />
      <Footer />
    </>
  );
}

export default App;
