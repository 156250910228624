import styled from "styled-components"

import Button from "../../atoms/button"

import {desktop} from "../../utils/breakpoint"

export const TourContainer = styled.section`
  background-image: url("/images/tour-bg-low.jpg");
  background-repeat: repeat;
  background-size: contain;
  background-position: center top;
  padding-top: 30px;
  position: relative;
  
  ${desktop} {
    background-image: url("/images/tour-bg.png");
    background-size: cover;
    min-height: 100vh;
  }
`

export const TourDates = styled.div`
  display: flex;
  flex-direction: column;
  margin: 35px auto 0;
  max-width: calc(1030px - 10px);
  padding: 0 10px 140px;
  
  ${desktop} {
    margin: 43px auto 0;
    padding: 0 10px 150px;
  }
`

export const Divider = styled.span`
  background: rgba(253, 234, 210, 0.3);
  border-radius: 10px;
  display: none;
  height: 1px;
  width: 100%;
  
  ${desktop} {
    display: unset;
  }
`

export const CenteredText = styled.div`
  margin: 0 auto;
  text-align: center;
`

export const LoadMoreButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 6px 40px 0;
  
  ${desktop} {
    display: none;
  }
`