import styled from "styled-components"

import {desktop} from "../../utils/breakpoint"

export const TourDateContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  
  ${desktop} {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0px;
  }
`

export const Text = styled.span`
  color: ${({theme}) => theme.palette.primary.text};
  font-size: 14px;
  font-weight: ${({emphasized}) => emphasized ? 600 : "normal"};
  line-height: 28px;
  text-align: center;
  padding: 3px;
  width: 100%;
  
  ${({maxWidth = 600}) => {
    if (maxWidth) {
      return `
        max-width: ${maxWidth}px;
      `
    }
    return ``
  }}
  
  ${desktop} {
    font-size: 18px;
    font-weight: 600;
    padding: 20px;
  }
`

export const Actions = styled.span`
  margin-top: 6px;
  padding: 10px 0 10px 10px;
  
  ${desktop} {
    margin-top: 0;
  }
`