import React from "react"

import Button from "../../atoms/button"
import SvgIcon from "../../atoms/svg-icon"

import {
  ReleasePreviewContainer,
  Artwork,
  InfoContainer,
  ContentWrapper,
  Header,
  Title,
  Subtitle,
  Info,
  LinksContainer
} from "./styles"

export default ({className, artworkUrl, name, year, spotifyArtistId, spotifyAlbumId, spotifyUrl, deezerUrl, youtubeUrl}) => {
  return (
    <ReleasePreviewContainer className={className}>
      <Artwork url={artworkUrl} />
      <InfoContainer>
        <Header>
          <ContentWrapper>
            <Title>{name}</Title>
            <Subtitle>Album - {year}</Subtitle>
          </ContentWrapper>
        </Header>
        <Info>
          <ContentWrapper flex={true}>
            <iframe
              title="player"
              src={spotifyArtistId ? `https://open.spotify.com/embed/artist/${spotifyArtistId}` : `https://open.spotify.com/embed/album/${spotifyAlbumId}`}
              width="100%"
              height="100%"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media"
            />
          </ContentWrapper>
          <ContentWrapper>
            <LinksContainer>
              {spotifyUrl && (
                <Button iconName="Spotify" size="sm" href={spotifyUrl} target="new">
                  Listen on Spotify
                </Button>
              )}
              {deezerUrl && (
                <Button
                  icon={<SvgIcon src="/images/brands/deezer.svg"/>}
                  size="sm"
                  href={deezerUrl}
                  target="new"
                >
                  Listen on Deezer
                </Button>
              )}
              {youtubeUrl && (
                <Button iconName="Youtube" size="sm" href={youtubeUrl} target="new">
                  Listen on Youtube
                </Button>
              )}
            </LinksContainer>
          </ContentWrapper>
        </Info>
      </InfoContainer>
    </ReleasePreviewContainer>
  )
}