import styled from "styled-components"

import YTSubscribe from "../../atoms/yt-subscribe"

import {desktop} from "../../utils/breakpoint"

export const VideosContainer = styled.section`
  background: linear-gradient(180deg, #1E0511 0%, #34091D 100%);
  background-size: contain;
  display: flex;
  flex-direction: column;
  padding: 100px 0 0;
  
  ${desktop} {
    min-height: 100vh;
    padding: 100px 0;
  }
`

export const VideosList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px auto 0px;
  max-width: calc(755px - 10px);
  padding: 20px 20px 0px 20px;
  width: 100%;
  
  ${desktop} {
    margin: 50px auto 30px;
    padding: 20px;
  }
`

export const StyledYTSubscribe = styled(YTSubscribe)`
  margin: 0 auto;
`