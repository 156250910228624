import React from "react"

import CtaButton from "../../atoms/cta-button"
import Icon from "../../atoms/icon"

import {
  MainSection,
  MainSectionFooter,
  Navbar,
  StyledLogo,
  HeroText,
  SocialNetworks,
  SocialNetworkLink,
  CtaContainer,
  BoldText,
  CtaButtonContainer
} from "./styles"

export default class Header extends React.PureComponent {
  componentDidMount() {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }
  
  onResize = () => {
    const mainSectionNode = document.getElementById("main-section");
    
    if (mainSectionNode) {
      mainSectionNode.style.height = `${window.innerHeight}px`
    }
  }

  render() {
    return (
      <MainSection id="main-section">
        <Navbar>
          <StyledLogo />
          <SocialNetworks>
            <SocialNetworkLink href="https://www.facebook.com/LosCrankers/" target="new">
              <Icon name="Facebook"/>
            </SocialNetworkLink>
            <SocialNetworkLink href="https://www.instagram.com/loscrankers/" target="new">
              <Icon name="Instagram"/>
            </SocialNetworkLink>
            <SocialNetworkLink href="https://twitter.com/loscrankers" target="new">
              <Icon name="Twitter"/>
            </SocialNetworkLink>
            <SocialNetworkLink href="https://www.youtube.com/user/TheCrankersTV" target="new">
              <Icon name="Youtube"/>
            </SocialNetworkLink>
          </SocialNetworks>
        </Navbar>
        <MainSectionFooter>
          <CtaContainer>
            <HeroText>
              <BoldText>Ciudad Jardín</BoldText> out now
            </HeroText>
            <CtaButtonContainer>
              <CtaButton href="https://cdbaby.lnk.to/CiudadJardin" target="new">Listen now</CtaButton>
            </CtaButtonContainer>
          </CtaContainer>
        </MainSectionFooter>
      </MainSection>
    )
  }
}