import React from "react"

import {
  ReleaseContainer,
  Artwork,
  Name,
  Type
} from "./styles"

export default ({artworkUrl, name, type, releaseYear, hideOnDesktop}) => {
  return (
    <ReleaseContainer hideOnDesktop={hideOnDesktop}>
      <Artwork src={artworkUrl}/>
      <Name>
        <strong>{name}</strong>{releaseYear && ` (${releaseYear})`}
      </Name>
      <Type>{type}</Type>
    </ReleaseContainer>
  )
}