import React from "react"
import moment from "moment-timezone"
import 'moment/min/locales';

import Button from "../../atoms/button"

import {
  TourDateContainer,
  Text,
  Actions
} from "./styles"

export default ({event}) => {
  const {
    displayName,
    start,
    location,
    uri
  } = event
  const startDate = moment(start.date).format("MMM M")

  return (
    <TourDateContainer>
      <Text maxWidth={100}>{startDate}</Text>
      <Text emphasized={true}>{displayName}</Text>
      <Text maxWidth={240}>{`${location.city}`}</Text>
      <Actions>
        {uri && (
          <Button href={uri} target="new">
            Tickets
          </Button>
        )}
      </Actions>
    </TourDateContainer>
  )
}