import styled from "styled-components"

import Button from "../../atoms/button"

import {desktop} from "../../utils/breakpoint"

export const MusicContainer = styled.section`
  background-image: url("/images/music-bg-low.jpg");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  background-color: #3d081f;
  padding-top: 100px;
  padding-bottom: 50px;
  
  ${desktop} {
    background-image: url("/images/music-bg.png");
    min-height: 100vh;
    padding-bottom: 100px;
  }
`

export const MusicWrapper = styled.div`
  align-items: center;
  display: ${({hideOnMobile}) => hideOnMobile ? "none" : "flex"};
  flex-direction: column;
  margin: 0 auto;
  max-width: ${({width = 1043}) => `calc(${width}px + 10px)`};
  padding: 0 10px;
  
  ${desktop} {
    display: flex;
    margin: ${({disableMargin = false}) => !disableMargin ? `50px auto 0` : `0 auto`};
  }
`

export const Releases = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
  
  ${desktop} {
    justify-content: space-between;
    margin-top: 75px;
    width: unset;
  }
`

export const LoadMoreButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px 40px 30px;
  
  ${desktop} {
    display: none;
  }
`