import React from "react"

import {
  SectionTitleContainer,
  StyledFeather,
  Text
} from "./styles"

export default ({children, svgIconSrc = "/images/feather.svg"}) => {
  return (
    <SectionTitleContainer>
      <StyledFeather src={svgIconSrc} />
      <Text>{children}</Text>
      <StyledFeather src={svgIconSrc} direction="right"/>
    </SectionTitleContainer>
  )
}