import React from "react"

import InstagramPost from "../../molecules/instagram-post"

import Instagram from "../../lib/instagram"

import {
  InstagramSwimlaneContainer
} from "./styles"

export default class InstagramSwimlane extends React.PureComponent {
  state = {
    posts: [],
    loading: true
  }

  componentDidMount() {
    this.loadPosts()
  }
  
  async loadPosts() {
    const {
      accountId
    } = this.props

    const response = await Instagram.feed(accountId, {limit: 6})
    let posts = []
    
    if (response.ok) {
      const json = await response.json()
      posts = json.data.map(({media_url, thumbnail_url, media_type, permalink}) => {
        return {
          imageUrl: thumbnail_url || media_url,
          permalink: permalink,
          type: media_type
        }
      })
    }
    
    this.setState({
      loading: false,
      posts
    })
  }

  render() {
    const {
      posts
    } = this.state

    return (
      <InstagramSwimlaneContainer>
        {posts.map(post => {
          return (
            <InstagramPost
              imageUrl={post.imageUrl}
              permalink={post.permalink}
              postType={post.type}
            />
          )
        })}
      </InstagramSwimlaneContainer>
    )
  }
}