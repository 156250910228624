import styled from "styled-components"

import {desktop} from "../../utils/breakpoint"

export const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 23px;
  
  ${desktop} {
    flex: 1
  }
`

export const Role = styled.div`
  color: ${({theme}) => theme.palette.secondary.accent};
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 6px;
  line-height: 17px;
  margin-left: 6px;
  text-align: center;
  text-transform: uppercase;
`

export const Name = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-top: 12px;
  text-align: center;
`

export const Email = styled.div`
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`