import React from "react"

import {
  Link,
  Image
} from "./styles"

export default ({className, height}) => {
  return (
    <Link href="/" target="self" className={className} height={height}>
      <Image src="/images/logo-color.png" alt="Los Crankers"/>
    </Link>
  )
}