import React from "react"

import {
  YTSubscribeContainer
} from "./styles"

export default ({className}) => {
  return (
    <YTSubscribeContainer className={className}>
      <div className="g-ytsubscribe" data-channel="TheCrankersTV" data-layout="full" data-count="default"></div>
    </YTSubscribeContainer>
  )
}