import React from "react"

import {
  InstagramPostContainer,
  InstagramIcon
} from "./styles"

export default ({className, imageUrl, postType, permalink}) => {
  return (
    <InstagramPostContainer
      className={className}
      href={permalink}
      target="new"
      src={imageUrl}
    >
      {postType !== "IMAGE" && <InstagramIcon icon={postType}/>}
    </InstagramPostContainer>
  )
}