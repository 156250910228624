const ACCESS_TOKEN = "EAADXOwz5vVUBAIDckSHm9f1rcZAJr37Cpy79pnrv59qEX8zN3nhmFFTgp4BTEERttY1jp4kVUK0YkpG9JpEz0ztnb8veNVA587qUZBjhCcHsoLl4CYaDeplnqr5LwFfe1UbEsK2LHC7IKpjJch75YieRdGZCZBV86oa51zrL4fvRg6YpPlPI9ZBwFzTx7ZBbcZD"
const BASE = "https://graph.facebook.com/v6.0/"

export default {
  feed: async (accountId, options = {}) => {
    return await fetch(BASE + `/${accountId}/media?` + new URLSearchParams({
      access_token: ACCESS_TOKEN,
      fields: "media_url,media_type,thumbnail_url,permalink",
      ...options
    }))
  }
}