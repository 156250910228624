import React from "react"

import {
  VideoPreviewContainer,
  Title,
  Subtitle
} from "./styles"

export default ({backgroundUrl, activeBackgroundUrl, url, title, subtitle}) => {
  return (
    <VideoPreviewContainer
      href={url}
      target="new"
      backgroundUrl={backgroundUrl}
      activeBackgroundUrl={activeBackgroundUrl}
    >
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </VideoPreviewContainer>
  )
}