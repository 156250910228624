import styled from "styled-components"
import { ReactSVG } from 'react-svg'

export const SvgWrapper = styled.span``

export const SvgImg = styled(ReactSVG)`
  svg {
    ${({direction}) => {
      if (direction !== "left") {
        return `
          transform: rotateY(0.5turn);
        `
      }
      return ``
    }}
  }
`