import styled from "styled-components"

import {desktop} from "../../utils/breakpoint"

export const InstagramPostContainer = styled.a`
  background-image: url("${({src}) => src}");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-basis: 33%;
  height: 125px;
  position: relative;
  
  ${desktop} {
    flex: 1;
    flex-basis: unset;
    height: 288px;
  }
`

export const InstagramIcon = styled.span`
  background-image: url("/images/instagram/bundle.png");
  background-position: ${({icon}) => {
    switch (icon) {
      case "VIDEO":
        return "0 -34px"
      case "CAROUSEL_ALBUM":
        return "0 0"
      default:
        return "0 -34px"
    }
  }};
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
`