import React from 'react';
import ReactDOM from 'react-dom';
import {ThemeProvider} from "styled-components"

import App from './App';
import * as serviceWorker from './serviceWorker';

import "./styles/reset.css"
import "./styles/fonts.css"
import "./styles/global.css"

const theme = {
  palette: {
    primary: {
      main: "#290717",
      text: "#FEF5E1",
      accent: "#FA6485"
    },
    secondary: {
      main: "#00FFA3",
      text: "#373D43",
      accent: "#F63D78"
    }
  }
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
