const BASE = `https://${process.env.NODE_ENV === "development" ? "staging." : ""}loscrankers.com/.netlify/functions`

export default {
  emailSignup: (email) => {
    return fetch(BASE + "/signup", {
      method: "POST",
      mode: "no-cors",
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        email
      })
    })
  }
}