import styled from "styled-components"

import DeezerFollowButton from "../../atoms/deezer-follow-button"

import {desktop} from "../../utils/breakpoint"

export const MusicFollowContainer = styled.div`
  ${desktop} {
    align-items: center;
    background: ${({theme}) => theme.palette.primary.main};
    border-radius: 45.5px;
    display: flex;
    flex-direction: row;
    padding: 12px 40px 12px 30px;
  }
`

export const BandImage = styled.span`
  background-image: url("${({url}) => url}");
  display: none;
  height: 64px;
  width: 64px;
  
  ${desktop} {
    display: unset;
  }
`

export const Text = styled.span`
  display: none;
  
  strong {
    font-size: 16px;
    font-weight: bold;
  }
  
  ${desktop} {
    flex-direction: column;
    font-size: 16px;
    line-height: 19px;
    margin: 0 24px 0 12px;
    display: flex;
  }
`

export const ButtonsContainer = styled.span`
  align-items: center;
  display: flex;
  flex-direction: column;
  
  ${desktop} {
    align-items: center;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
  }
`

export const StyledDeezerFollowButton = styled(DeezerFollowButton)`
  margin-bottom: 15px;
  
  ${desktop} {
    margin-bottom: 0px;
    margin-right: 20px;
  }
`