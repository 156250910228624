import React from "react"

import {
  MusicFollowContainer,
  BandImage,
  Text,
  ButtonsContainer,
  StyledDeezerFollowButton
} from "./styles"

export default ({imageUrl, name, spotifyArtistUri, deezerUrl}) => {
  return (
    <MusicFollowContainer>
      {imageUrl && <BandImage url={imageUrl}/>}
      <Text>
        <strong>{name}</strong>
        Follow us:
      </Text>
      <ButtonsContainer>
        {deezerUrl && (
          <StyledDeezerFollowButton href={deezerUrl}/>
        )}
        {spotifyArtistUri && (
          <iframe
            title="follow-badge"
            src={`https://embed.spotify.com/follow/1/?uri=${spotifyArtistUri}&size=basic&theme=dark`}
            width="137"
            height="25"
            scrolling="no"
            frameBorder="0"
            allowtransparency="true"
          />
        )}
      </ButtonsContainer>
    </MusicFollowContainer>
  )
}