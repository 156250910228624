import styled from "styled-components"

import {desktop} from "../../utils/breakpoint"

export const ReleaseContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-basis: 44%;
  margin: 0 20px 40px;
  
  ${desktop} {
    display: ${({hideOnDesktop}) => hideOnDesktop ? "none" : "flex"};
    flex-basis: 33%;
    margin: 0 0 100px;
  }
`

export const Artwork = styled.img`
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.32), 0px 0px 2px rgba(0, 0, 0, 0.04);
  height: 250px;
  width: 250px;
`

export const Name = styled.span`
  color: ${({theme}) => theme.palette.primary.text};
  font-size: 18px;
  line-height: 135%;
  margin-top: 15px;
  text-align: center;
  text-transform: uppercase;
  
  strong {
    font-weight: bold;
  }
`

export const Type = styled.span`
  color: ${({theme}) => theme.palette.primary.text};
  font-size: 14px;
  line-height: 135%;
  opacity: 0.3;
  text-align: center;
`