import styled, {css} from "styled-components"

import Icon from "../icon"

const BaseButton = css`
	align-items: center;
	background-color: #00FFA3;
	box-shadow: 0px 2px 4px rgba(96, 97, 112, 0.16), 0px 0px 1px rgba(40, 41, 61, 0.04);
	border: none;
	border-radius: 3px;
	color: #373D43;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	font-family: ProximaNova;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 3px;
	line-height: 24px;
	text-decoration: none;
	text-transform: uppercase;
	transition: transform 0.2s ease;
	padding: 15px 58px;

	&:hover {
		background-color: #1AE69C;
		transform: translateY(-2px);
	}

	&:active {
		background-color: #16C083;
		color: #8AFFD5;
	}
	
	${({active}) => {
		if (active) {
			return `
				background-color: #16C083;
				color: #8AFFD5;
			`
		}
		return ``
	}}
`

export const CtaButton = styled.button`
	${BaseButton}
`

export const CtaButtonLink = styled.a`
	${BaseButton}
`

export const StyledIcon = styled(Icon)`
	margin-right: 8px;
`