import styled from "styled-components"

import Icon from "../icon"

export const ButtonContainer = styled.a`
	background-color: transparent;
  border: 1px solid #f00065;
	border-radius: 3px;
	color: #FECBCC;
	font-size: ${({size}) => {
		switch (size) {
			case "xs":
			case "sm":
				return "12px"
			default:
				return "14px"
		}
	}};
	font-weight: ${({size}) => {
		switch (size) {
			case "xs":
			case "sm":
				return "400"
			default:
				return 600
		}
	}};
	line-height: ${({size}) => {
		switch (size) {
			case "xs":
			case "sm":
				return "16px"
			default:
				return "24px"
		}
	}};
	text-decoration: none;
	padding: ${({size}) => {
		switch (size) {
			case "xs":
			case "sm":
				return "8px";
			default:
				return "10px 33px"
		}
	}};
  
  &:hover {
    background-color: #F00065;
  }
  
  &:active {
    background-color: #A40567;
    border: 1px solid #A40567;
    color: #FFFFFF;
  }
`

export const StyledIcon = styled(Icon)`
	margin-right: 8px;
`

export const CustomIconWrapper = styled.span`
	margin-right: 8px;
	vertical-align: top;
`