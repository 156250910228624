import React from "react"

import {
  ButtonContainer,
  StyledIcon,
  CustomIconWrapper
} from "./styles"

export default ({children, icon, iconName, size = "lg", ...rest}) => {
  return (
    <ButtonContainer size={size} {...rest}>
      <>
        {icon && (
          <CustomIconWrapper>
            {icon}
          </CustomIconWrapper>
        )}
        {iconName && <StyledIcon size={size} name={iconName}/>}
        {children}
      </>
    </ButtonContainer>
  )
}