import React from "react"

import BottomDecoration from "../../atoms/bottom-decoration"

import InstagramSwimlane from "../../organisms/instagram-swimlane"

import {
  PhotosContainer,
  PhotoGrid,
  LargePhoto,
  SidePhotos,
  MediumPhoto,
  SmallPhotos,
  SmallPhoto,
  MiniPhoto,
  MobilePhoto,
  FloatingCtaButton
} from "./styles"

export default class Photos extends React.PureComponent {
  render() {
    return (
      <PhotosContainer>
        <PhotoGrid style={{ display: "none" }}>
          <LargePhoto src="/images/band/large.jpg"/>
          <SidePhotos>
            <MediumPhoto src="/images/band/medium.jpg"/>
            <SmallPhotos>
              <SmallPhoto src="/images/band/small-1.jpg"/>
              <MiniPhoto src="/images/band/small-2.jpg"/>
            </SmallPhotos>
          </SidePhotos>
        </PhotoGrid>
        <MobilePhoto style={{ display: "none" }} src="/images/band/medium.jpg"/>
        <InstagramSwimlane
          accountId="17841402641954446"
        />
        <MobilePhoto style={{ display: "none" }} src="/images/band/small-2.jpg"/>
        <FloatingCtaButton
          href="https://www.instagram.com/loscrankers/"
          target="new"
          iconName="Instagram"
        >
          Follow us on Instagram
        </FloatingCtaButton>
        <BottomDecoration src="/images/photos-footer.svg" height={69} mobileHeight={18} />
      </PhotosContainer>
    )
  }
}