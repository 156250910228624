import React from "react"

import SectionTitle from "../../atoms/section-title"
import BottomDecoration from "../../atoms/bottom-decoration"
import TourDate from "../../molecules/tour-date"

import Songkick from "../../lib/songkick"

import {
  TourContainer,
  TourDates,
  CenteredText,
  Divider,
  LoadMoreButton
} from "./styles"

const EVENTS_PER_PAGE = 5

export default class Tour extends React.PureComponent {
  state = {
    events: [],
    loading: true,
    displayedItems: EVENTS_PER_PAGE,
    canLoadMore: true
  }
  loadMoreBtn = React.createRef()

  componentDidMount() {
    this.loadEvents()
    this.loadMoreBtn = document.getElementById("load-more-dates")
    this.onResize()
    window.addEventListener('resize', this.onResize)
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }
  
  onResize = () => {
    const {
      canLoadMore
    } = this.state

    if (this.loadMoreBtn) {
      if (this.loadMoreBtn.clientHeight === 0) {
        if (canLoadMore) {
          this.setState({
            canLoadMore: false
          })
        }
      } else {
        if (!canLoadMore) {
          this.setState({
            canLoadMore: true
          })
        }
      }
    }
  }
  
  async loadEvents() {
    const response = await Songkick.calendar()
    if (response && response.ok) {
      let newEvents = []
      const json = await response.json()

      if (json.resultsPage.results.event) {
        newEvents = json.resultsPage.results.event
      }
      
      this.setState({
        events: newEvents,
        loading: false
      })
      
      this.onResize()
    }
  }
  
  loadMoreEvents = () => {
    const {displayedItems} = this.state

    this.setState({
      displayedItems: displayedItems + EVENTS_PER_PAGE
    })
  }

  render() {
    const {
      events,
      loading,
      displayedItems,
      canLoadMore
    } = this.state
    const tourDates = canLoadMore ? events.slice(0, displayedItems) : events

    return (
      <TourContainer>
        <SectionTitle>
          Tour Dates
        </SectionTitle>
        <TourDates>
          {loading && (
            <CenteredText>
              Loading...
            </CenteredText>
          )}
          {!loading && events.length === 0 && (
            <CenteredText>
              No events announced yet
            </CenteredText>
          )}
          {tourDates.map((event, index) => {
            return (
              <React.Fragment key={`event-${event.id}`} id={index + 1 === displayedItems - EVENTS_PER_PAGE && "tour-dates"}>
                <TourDate event={event}/>
                <Divider />
              </React.Fragment>
            )
          })}
          {!loading && events.length > displayedItems && (
            <LoadMoreButton
              id="load-more-dates"
              href="#tour-dates"
              ref={this.loadMoreBtn}
              onClick={this.loadMoreEvents}
            >
              Load More
            </LoadMoreButton>
          )}
        </TourDates>
        <BottomDecoration src="/images/tour-footer.svg" height={137} />
      </TourContainer>
    )
  }
}