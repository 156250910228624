import styled from "styled-components"

import SvgIcon from "../svg-icon"

import {desktop} from "../../utils/breakpoint"

export const SectionTitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const Text = styled.h2`
  color: ${({theme}) => theme.palette.primary.accent};
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 10px;
  line-height: 70px;
  margin-right: -10px;
  text-align: center;
  text-shadow: 0px 2px 4px rgba(240, 0, 101, 0.6), 0px 0px 1px rgba(240, 0, 101, 0.8);
  text-transform: uppercase;
  
  ${desktop} {
    letter-spacing: 24px;
    margin-right: -24px;
  }
`

export const StyledFeather = styled(SvgIcon)`
  display: none;
  margin: 0 35px;
  
  ${desktop} {
    display: unset;
  }
`