import React from "react"

import {
  CtaButton,
  CtaButtonLink,
  StyledIcon
} from "./styles"

export default ({
  variant = "a",
  children,
  iconName,
  size = "lg",
  active = false,
  ...rest
}) => {
  const CtaButtonContainer = variant === "a" ? CtaButtonLink : CtaButton

  return (
    <CtaButtonContainer active={active} {...rest}>
      {iconName && <StyledIcon size={size} name={iconName}/>}
      {children}
    </CtaButtonContainer>
  )
}