import React from "react"

import SectionTitle from "../../atoms/section-title"

import Release from "../../molecules/release"
import MusicFollow from "../../molecules/music-follow"

import ReleasePreview from "../../organisms/release-preview"

import {
  MusicContainer,
  MusicWrapper,
  Releases,
  LoadMoreButton
} from "./styles"

const RELEASES_PER_PAGE = 4
const RELEASES = [
  {
    artworkUrl: "/images/releases/ciudad-jardin.png",
    name: "Ciudad Jardín",
    hideOnDesktop: true,
    type: "Album",
    releaseYear: "2020"
  },
  {
    artworkUrl: "/images/releases/selva.png",
    name: "Selva",
    type: "Single",
    releaseYear: "2019"
  },
  {
    artworkUrl: "/images/releases/controlar-no-amar.png",
    name: "Controlar No Amar",
    type: "Single",
    releaseYear: "2019"
  },
  {
    artworkUrl: "/images/releases/a-ciegas.png",
    name: "A Ciegas",
    type: "Single",
    releaseYear: "2019"
  },
  {
    artworkUrl: "/images/releases/room-sessions.jpg",
    name: "Room Sessions",
    type: "EP",
    releaseYear: "2018"
  },
  {
    artworkUrl: "/images/releases/cranky.jpg",
    name: "Cranky",
    type: "EP",
    releaseYear: "2016"
  },
  {
    artworkUrl: "/images/releases/first-trip.jpg",
    name: "First Trip",
    type: "EP",
    releaseYear: "2014"
  }
]

export default class Music extends React.PureComponent {
  state = {
    displayedItems: RELEASES_PER_PAGE,
    canLoadMore: true
  }
  loadMoreBtn = null
  
  componentDidMount() {
    this.loadMoreBtn = document.getElementById("load-more-releases")
    this.onResize()
    window.addEventListener('resize', this.onResize)
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }
  
  onResize = () => {
    const {
      canLoadMore
    } = this.state

    if (this.loadMoreBtn) {
      if (this.loadMoreBtn.clientHeight === 0) {
        if (canLoadMore) {
          this.setState({
            canLoadMore: false
          })
        }
      } else {
        if (!canLoadMore) {
          this.setState({
            canLoadMore: true
          })
        }
      }
    }
  }
  
  loadMoreReleases = () => {
    const {displayedItems} = this.state

    this.setState({
      displayedItems: displayedItems + RELEASES_PER_PAGE
    })
  }

  render() {
    const {
      displayedItems,
      canLoadMore
    } = this.state
    
    const releases = canLoadMore ? RELEASES.slice(0, displayedItems) : RELEASES

    return (
      <MusicContainer>
        <SectionTitle svgIconSrc="/images/tiger.svg">
          Music
        </SectionTitle>
        <MusicWrapper hideOnMobile={true}>
          <ReleasePreview
            artworkUrl="/images/releases/ciudad-jardin.png"
            name="Ciudad Jardín"
            spotifyAlbumId="4A0fkMkl0eHg9yLlk3AAYQ"
            spotifyUrl="https://open.spotify.com/album/4A0fkMkl0eHg9yLlk3AAYQ?si=qqg5wuu9QNGSHrwQR71FpQ"
            deezerUrl="https://www.deezer.com/en/album/136641782"
            youtubeUrl="https://www.youtube.com/user/TheCrankersTV"
            year={2020}
          />
        </MusicWrapper>
        <MusicWrapper width={1176}>
          <Releases>
            {releases.map(({artworkUrl, name, type, releaseYear, hideOnDesktop}, index) => {
              return (
                <Release
                  key={`release-${index.toString()}`}
                  artworkUrl={artworkUrl}
                  hideOnDesktop={hideOnDesktop}
                  name={name}
                  type={type}
                  releaseYear={releaseYear}
                />
              )
            })}
          </Releases>
        </MusicWrapper>
        {RELEASES.length > displayedItems && (
          <LoadMoreButton
            id="load-more-releases"
            href="#releases"
            onClick={this.loadMoreReleases}
          >
            Load More
          </LoadMoreButton>
        )}
        <MusicWrapper disableMargin={true}>
          <MusicFollow
            imageUrl="/images/band-circular-small.png"
            name="Los Crankers"
            spotifyArtistUri="spotify:artist:6BV6125nwCfygQI36SXv4l"
            deezerUrl="https://www.deezer.com/en/artist/14995879"
          />
        </MusicWrapper>
      </MusicContainer>
    )
  }
}