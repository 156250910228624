import React from "react"

import SectionTitle from "../../atoms/section-title"

import VideoPreview from "../../molecules/video-preview"

import {
  VideosContainer,
  VideosList,
  StyledYTSubscribe
} from "./styles"

const VIDEOS = [
  {
    title: "Selva",
    url: "https://www.youtube.com/watch?v=iXZ94-dOlQQ",
    imageUrl: "/images/videos-thumbnails/selva.jpg",
    gifUrl: "/images/videos-thumbnails/selva.gif",
  },
  {
    title: "Insatiable",
    url: "https://www.youtube.com/watch?v=Gdc1ImGMGW0",
    imageUrl: "/images/videos-thumbnails/insatiable.jpg",
    gifUrl: "/images/videos-thumbnails/insatiable.gif"
  }
]

export default class Videos extends React.PureComponent {
  render() {
    return (
      <VideosContainer>
        <SectionTitle svgIconSrc="/images/rooster.svg">
          Videos
        </SectionTitle>
        <VideosList>
          {VIDEOS.map(video => {
            return (
              <VideoPreview
                backgroundUrl={video.imageUrl}
                activeBackgroundUrl={video.gifUrl}
                key={`video-${video.title.toLowerCase()}`}
                title={video.title}
                url={video.url}
                subtitle="Play in Youtube"
              />
            )
          })}
        </VideosList>
        <StyledYTSubscribe />
      </VideosContainer>
    )
  }
}