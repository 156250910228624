import styled from "styled-components"

export const DeezerFollowButtonContainer = styled.a`
  align-items: center;
  background: #FB7904;
  border-radius: 100px;
  color: #fff;
  display: flex;
  flex-direction: row;
  margin-top: 2px;
  padding: 4px 10px;
  text-decoration: none;
`

export const Text = styled.span`
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  margin-left: 12px;
  text-transform: uppercase;
`