import React from "react"

import {
  SvgWrapper,
  SvgImg
} from "./styles"

export default ({className, src, direction = "left"}) => {
  return (
    <SvgWrapper className={className}>
      <SvgImg
        src={src}
        direction={direction}
        wrapper="span"
      />
    </SvgWrapper>
  )
}