import styled from "styled-components"

import Logo from "../../atoms/logo"

import {desktop} from "../../utils/breakpoint"

export const MainSection = styled.section`
  background: linear-gradient(180deg,#450B26 0%,rgba(69,11,38,0) 21.56%),linear-gradient(180deg,rgba(41,7,23,0) 50%,#290717 80%),url('/images/main-bg-low.jpg') no-repeat;
  background-position: center top;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  position: relative;
  
  ${desktop} {
    background: linear-gradient(180deg, #450B26 0%, rgba(69, 11, 38, 0) 21.56%), linear-gradient(180deg, rgba(41, 7, 23, 0) 58.06%, #290717 96.28%), url('/images/main-bg-hi.jpg') no-repeat;
    background-position: center 15%;
    background-size: 100% auto;
  }
`

export const MainSectionFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const Navbar = styled.header`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 73px;
  width: 100%;
  padding: 0 10px 0 20px;
  
  ${desktop} {
    height: 85px;
  }
`

export const StyledLogo = styled(Logo)`
  height: 44px;
  
  ${desktop} {
    height: 56px;
    margin-left: 25px;
  }
`

export const SocialNetworks = styled.nav`
  padding-bottom: 14px;

  ${desktop} {
    margin-right: 31px;
    padding-bottom: 20px;
  }
`

export const SocialNetworkLink = styled.a`
  color: ${({theme}) => theme.palette.primary.text};
  font-size: 17px;
  margin-right: 0px;
  padding: 10px;
  
  ${desktop} {
    margin-right: 12px;
  }
`

export const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15vh;
  text-align: center;
  width: 100%;

  ${desktop} {
    align-items: flex-end;
    margin-bottom: 0px;
    padding: 0 64px 90px 0;
    text-align: unset;
    width: unset;
  }
`

export const BoldText = styled.strong`
  display: block;
  
  ${desktop} {
    display: inline-block;
  }
`

export const CtaButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;

  ${desktop} {
    justify-content: flex-end;
  }
`

export const HeroText = styled.h1`
  font-size: 36px;
  font-weight: normal;
  margin-bottom: 0;
  text-transform: uppercase;
`