import styled from "styled-components"

import {desktop} from "../../utils/breakpoint"

export const VideoPreviewContainer = styled.a`
  align-items: center;
  background-image: linear-gradient(0deg, rgba(37, 6, 21, 0.7), rgba(37, 6, 21, 0.7)), url("${({backgroundUrl}) => backgroundUrl}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.32), 0px 0px 2px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 204px;
  margin-bottom: 25px;
  text-decoration: none;
  max-width: 745px;
  width: 100%;
  
  &:hover {
    background-image: linear-gradient(0deg, rgba(37, 6, 21, 0.7), rgba(37, 6, 21, 0.7)), url("${({activeBackgroundUrl}) => activeBackgroundUrl}"), url("${({backgroundUrl}) => backgroundUrl}");
  }
  
  ${desktop} {
    height: 447px;
  }
`

export const Title = styled.h3`
  color: ${({theme}) => theme.palette.primary.text};
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
  
  ${desktop} {
    font-size: 54px;
    line-height: 66px;
  }
`

export const Subtitle = styled.span`
  color: ${({theme}) => theme.palette.primary.text};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-top: 8px;
  opacity: 0.8;
  text-align: center;
  
  ${desktop} {
    line-height: 17px;
    margin-top: 10px;
  }
`