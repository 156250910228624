import styled from "styled-components"

import {desktop} from "../../utils/breakpoint"

export const BottomDecoration = styled.span`
  background: url("${({src}) => src}") repeat-x;
  background-size: auto 100%;
  bottom: -1px;
  height: ${({mobileHeight, height = 0}) => mobileHeight || height}px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  
  ${desktop} {
    background-size: auto;
    height: ${({height = 0}) => height}px;
  }
`