import styled from "styled-components"

export const ReleasePreviewContainer = styled.div`
  border-radius: 0px 12px 12px 0px;
  box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.15), 0px 0px 2px rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 0 auto;
  width: 100%;
`

export const Artwork = styled.div`
  background-image: url("${({url}) => url}");
  background-size: cover;
  display: flex;
  height: 529px;
  width: 529px;
`

export const InfoContainer = styled.div`
  background-color: #280616;
  border-radius: 0px 12px 12px 0px;
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 30px 0 10px;
`

export const ContentWrapper = styled.div`
  ${({flex}) => {
    if (flex) {
      return `
        display: flex;
        flex-direction: column;
        flex: 1;
      `
    }
    return ""
  }}
  padding: 0px 44px;
`

export const Title = styled.h1`
  color: ${({theme}) => theme.palette.primary.text};
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 44px;
  text-transform: uppercase;
`

export const Subtitle = styled.span`
  color: #7C415C;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  opacity: 0.8;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 18px 0 23px;
`