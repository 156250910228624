import React from "react"

import {
  ContactInfoContainer,
  Role,
  Name,
  Email
} from "./styles"

export default ({role, name, email}) => {
  return (
    <ContactInfoContainer>
      {role && <Role>{role}</Role>}
      {name && <Name>{name}</Name>}
      {email && <Email>{email}</Email>}
    </ContactInfoContainer>
  )
}