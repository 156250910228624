import React from "react"

import SvgIcon from "../../atoms/svg-icon"

import {
  DeezerFollowButtonContainer,
  Text
} from "./styles"

export default ({className, href}) => {
  return (
    <DeezerFollowButtonContainer className={className} href={href} target="new">
      <SvgIcon src="/images/brands/deezer.svg" />
      <Text>Follow</Text>
    </DeezerFollowButtonContainer>
  )
}