import React from "react"

import {
  BottomDecoration
} from "./styles"

export default ({className, src, height, mobileHeight}) => {
  return (
    <BottomDecoration src={src} className={className} height={height} mobileHeight={mobileHeight} />
  )
}