import React from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Solids from '@fortawesome/free-solid-svg-icons'
import * as Brands from '@fortawesome/free-brands-svg-icons'

export default ({className, name, size = "lg"}) => {
  const icon = Solids[`fa${name}`] || Brands[`fa${name}`]
  
  if (!icon) {
    return null
  }
  
  return (
    <FontAwesomeIcon className={className} icon={icon} size={size} />
  )
}